import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { equal, from, MetastoreService } from '@konnektu/metastore';
import { API_URL } from '@konnektu/tokens';
import { firstValueFrom, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private readonly apiUrl = inject(API_URL);

  private readonly metastore = inject(MetastoreService);

  private readonly httpClient = inject(HttpClient);

  getSupersetGuestToken(dashboardId: number) {
    return firstValueFrom(
      this.httpClient
        .get<{
          token: string;
        }>(`${this.apiUrl}/superset/guest-token/${dashboardId}`)
        .pipe(map((t) => t.token))
    );
  }

  getSupersetUrl() {
    return this.httpClient.get(`${this.apiUrl}/superset/url`, {
      responseType: 'text'
    });
  }

  getSupersetDashboardById(dashboardId: number) {
    return this.metastore.find<{ Id: number; Name: string; Uuid: string }>(
      from('UISupersetDashboard')
        .select(['Id', 'Name', 'Uuid'])
        .where(equal('Id', dashboardId))
    );
  }

  importNewDashboard(name: string, uuid: string) {
    return this.metastore.insert('UISupersetDashboard', {
      Name: name,
      Uuid: uuid
    });
  }

  queryAllDashboards() {
    return this.metastore.select<{ Id: number; Name: string; Uuid: string }>(
      from('UISupersetDashboard').select(['Id', 'Name', 'Uuid'])
    );
  }
}
